import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class RegiaoService extends GerinusService {
 
    constructor(
        public http: HttpClient
    ) {
        super(http);
        this.entityName = "Regiao";
        this.idField = "REG_CodigoRegiao";
    }
}